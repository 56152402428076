import React from "react";
import Layout from "../../component/layout/Layout";
import { Helmet } from "react-helmet";
import { Card, Breadcrumb } from "antd";
import { itemRender } from '../../utils/common'
import { useNavigate } from 'react-router'
import { HomeOutlined, FilePdfFilled, FileTextFilled } from '@ant-design/icons';

const itemMenu = [
  {
    href: `/generate-document`,
    title: (
      <>
        <span><HomeOutlined style={{marginRight:"10px", color:"#898787"}}/>Generate Document</span>
      </>
    )
  },
]

const App = () => {
  const navigate = useNavigate()
  return <>
  <Layout activeKey="generate-document">
    <Helmet>
      <title>Document Manager</title>
    </Helmet>
    <Breadcrumb itemRender={itemRender} items={itemMenu} />
    <div style={{padding:"20px"}}>
      <h2 style={{ textAlign: 'left',color:"#646965" }}>Generate Document</h2>
      <Card className='card-list doc' style={{height:"500px"}} bordered={false}>
        <div style={{display:"flex", gap:"30px", justifyContent:"center", marginTop:"50px"}}>
          <div className="generate-set" onClick={()=>{navigate(`/generate-document/invoice`)}}>
            <FilePdfFilled style={{color:"#63A8C3", fontSize:"100px"}}/>
            <span>Generate Invoice set</span>
          </div>
          <div className="generate-set" onClick={()=>{navigate(`/generate-document/delivery`)}}>
            <FileTextFilled style={{color:"#63A8C3", fontSize:"100px"}}/>
            <span>Generate Delivery note set</span>
          </div>
        </div>
      </Card>
    </div>
  </Layout>
  </>;
};

export default App;
