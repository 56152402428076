import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { getStorage } from '../../hooks/useCookieStorage';
import { jwtDecode } from 'jwt-decode';
// import { AppBar } from "./AppBar";

const ProtectedLayout = () => {
  const { user, logout } = useAuth();
  const outlet = useOutlet();
  if (!user) {
    logout();
    return <Navigate to="/login" />;
  }

  try {
    const value = getStorage("token");
    if (value) {
      const decodedToken = jwtDecode(value);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if(decodedToken.exp < currentTime){
        logout();
      }
    }else{
      logout();
    }
  } catch (err) {
    logout();
  }

  return (
    <div>
      {outlet}
    </div>
  );
};

export default ProtectedLayout;
