/* istanbul ignore file */
import { useState, createElement } from 'react'
import {
  CloseOutlined,
  HomeOutlined,
  SettingOutlined,
  UnlockFilled
} from '@ant-design/icons'
import { Layout, Menu, Button, Drawer, ConfigProvider } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import {
  useNavigate
} from "react-router-dom"
import { useAuth } from "../../hooks/useAuth";

const { Header, Content, Footer, Sider } = Layout
const _items = [
  {
    key: 'generate-document',
    icon: createElement(HomeOutlined),
    label: `Generate Document`,
    link: '/generate-document'
  },
]
const colorBgContainer = '#F7F7F7'
const LayoutComponent = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const drawerStyles = {
    mask: {
      backdropFilter: 'blur(10px)',
    },
    content: {
      boxShadow: '-10px 0 10px #666',
    },
    header: {
      // borderBottom: `1px solid ${token.colorPrimary}`,
    },
    body: {
      // fontSize: token.fontSizeLG,
    },
    footer: {
      // borderTop: `1px solid ${token.colorBorder}`,
    },
  }
  let itemss = _items

  // if (splited && splited.length > 0) {
  //   const find = _items.find(item => item.link === `/${splited[1]}`)
  //   if (find) {
  //     itemss.push(find)
  //   }
  // }


  const { decodedUser } = useAuth();
  if(!decodedUser){
    window.location.href = "/login";
  }
  if(decodedUser?.isAdmin){
    itemss = [...itemss, {
      key: 'setting-document',
      icon: createElement(SettingOutlined),
      label: `Setting Document`,
      link: '/setting-document'
    }]
  }

  return (<div>
    <Layout >
      <Sider
        className="app-ui-right"
        width={250}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#3b4546'
        }}
      >
        <div style={{ color: '#fff' }}>
          <div style={{textAlign:"center", marginTop:"10px"}}>
            {/* <img src={logo} className="img-logo" alt=""
              style={{marginLeft: 'auto',marginRight: 'auto',marginTop: 0,marginBottom: 0,height: 100,width: 200}}
              onClick={() => {
                setOpen(!open)
              }}
              >
            </img> */}
            <div style={{ fontSize:"35px", textShadow:"#000000 0px 0px 5px"}} onClick={() => {
                // setOpen(!open)
              }}>Document Manager</div>
            <hr />
          </div>
          <Menu
            key={'menu1'}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[props.activeKey]}
            items={itemss}
            style={{ backgroundColor: '#3b4546' }}
            selectable={true}
            onSelect={(e)=>{
              navigate(`${e.item.props.link}`)
            }
            }
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, backgroundColor: '#3b4546', display:"flex",justifyContent: 'space-between'}} className="header-ui" >
          <div className="mobild-ui">
            <Button icon={<MenuOutlined />} style={{ borderRadius: 6 }} className="bamberger-ui"
              onClick={() => {
                setOpen(!open)
              }}>
            </Button>
            <ConfigProvider
              drawer={{ styles: drawerStyles }}
            >
              <Drawer
                title={
                  <>
                  
                  <div style={{display:"flex", fontSize:"20px", textShadow:"#000000 0px 0px 5px", fontWeight:400, textAlign: 'center', color:"#ffffff", marginTop:"12px" }} onClick={() => {
                  }}>
                    <span>
                      Document Manager
                    </span>
                    <Button icon={<MenuOutlined />} style={{ borderRadius: 6 }} className="bamberger-ui"
                      onClick={() => {
                        setOpen(!open)
                      }}>
                    </Button>
                  </div>
                  <hr style={{width:"100%"}}/>
                  </>
                }
                placement={'left'}
                closable={false}
                onClose={() => {
                  setOpen(false)
                }}
                open={open}
                key={'left'}
                styles={drawerStyles}
                closeIcon={<CloseOutlined />}
              >
                <Menu
                  key={'menu2'}
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={[props.activeKey]}
                  items={itemss}
                  style={{ backgroundColor: '#3b4546' }}
                  onSelect={(e)=>{
                    navigate(`${e.item.props.link}`)
                  }}
                />
                <div className='logout-mobile' onClick={()=>{
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    window.location.href = "/login";
                }}>
                Log out <UnlockFilled style={{fontSize: "20px", paddingRight:"5px"}}/></div>
              </Drawer>
            </ConfigProvider>
          </div>
          <div className='account-login'>
            <div className='account-login-container'>
              <span>Document Manager</span>
              <div className='account-logout'>
                <span style={{marginRight:"10px"}}>{decodedUser?.username}</span>
                <span>
                  <UnlockFilled style={{fontSize: "20px", color:"#63A8C3", paddingRight:"5px"}} onClick={()=>{
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    window.location.href = "/login";
                  }}/>
                  <span style={{cursor:"pointer"}} onClick={()=>{
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    window.location.href = "/login";
                  }}>Log Out</span>
                </span>
              </div>
            </div>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ textAlign: 'center', background: colorBgContainer }}>
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', backgroundColor: 'transparent', }}>

        </Footer>
      </Layout>
    </Layout>
  </div>)
}

export default LayoutComponent