import React, { useState } from 'react';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import './index.css';

const FloatInput = (props) => {
  const [focus, setFocus] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  let { label, value, placeholder, type, prefix } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let props_ = { ...props };
  delete props_['placeholderInput'];
  delete props_['focus'];

  return (
    <div
      className='float-label'
      onBlur={() => {
        setFocus(false);
      }}
      data-testid='input-id'
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props_}
        type={type === 'password' && showPassword ? 'text' : type}
        onChange={props.onChange}
        placeholder={focus || props.focus ? props.placeholderInput : ''}
        prefix={prefix}
        suffix={
          type === 'password' ? (
            showPassword ? (
              <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
            ) : (
              <EyeOutlined onClick={togglePasswordVisibility} />
            )
          ) : null
        }
      />
      <label htmlFor={props.id} className={`${labelClass} ${props.disabled ? 'disabled' : ''}`}>
        {props.required ? <span style={{ color: '#D1333D' }}> * </span> : <span style={{ color: '#D1333D' }}>  </span>} {isOccupied ? label : placeholder}
      </label>
    </div>
  );
};

export default FloatInput;
