/* istanbul ignore file */

import { useState } from "react";
import { jwtDecode } from 'jwt-decode';

export const useCookieStorage = (keyName, defaultValue) => {
  
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = getStorage(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    console.log("🚀 ~ setValue ~ newValue:", newValue)
    try {
      // window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const useDecodeUser = (keyName, defaultValue) => {
  try {
    const value = getStorage(keyName);
    if (value) {
      const decodedToken = jwtDecode(value);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        return null;
      }
      return decodedToken;
    } else {
      return defaultValue;
    }
  } catch (err) {
    return defaultValue;
  }
};


export const getStorage = (name) => {
  let cookieName = `${name}=`;
  let cookieArray = document.cookie.split(';');
  
  for(let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(cookieName) == 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
  return null;
}

