/* istanbul ignore file */
import { post, get, postForm } from './RequestApi'

export function postVerify(header, data) {
  return postForm({ url: `/document/verify`, header, data })
}

export function postLogin(body) {
  return post({ url: `/auth/login`, data : body});
}

export function getDocumentType(params) {
  return get({ url: `/dropdown/document-type`, params});
}

export function getDocumentSetting(params) {
  return get({ url: `/document/setting`, params});
}

export function postUpload(cus, data) {
  return postForm({ url: `/document/upload/${cus}`, data })
}

export function postGenerate(data) {
  return post({ url: `/document/generate`, data })
}

export function postSendEmail(data) {
  return post({ url: `/document/send-email`, data })
}
