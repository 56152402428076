import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/layout/Layout";
import { Helmet } from "react-helmet";
import { Button, Card, Upload, Form, Input, Breadcrumb, Tooltip, InputNumber, Modal, Row, Col, Spin, DatePicker} from "antd";
import { itemRender } from '../../utils/common'
import { HomeOutlined, UploadOutlined, PlusOutlined, MinusOutlined, CloseOutlined, EditFilled, DownloadOutlined, PrinterFilled, SaveFilled} from '@ant-design/icons';
import { postVerify, getDocumentSetting, getDocumentType, postUpload, postGenerate, postSendEmail } from '../../services/generateDocument'
import { useNavigate } from 'react-router'
import Notification from "../../services/Notification";
import SkeletonLoading from '../../component/SkeletonLoading';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import dayjs from "dayjs";

const { TextArea } = Input;

const itemMenu = [
  {
    href: `/generate-document`,
    title: (
      <>
        <span><HomeOutlined style={{marginRight:"10px"}}/>Generate Document</span>
      </>
    )
  },{
    href: `/generate-document/invoice`,
    title: (
      <>
        <span>Upload invoice</span>
      </>
    )
  },
]

const App = () => {
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [creditNote, setCreditNote] = useState([]);
  const [mockSettingOther, setMockSettingOther] = useState();
  const mockSetting = useRef({});
  const [customerSetting, setCustomerSetting] = useState({});
  const [transactionId, setTransactionId] = useState();
  const [contentData, setContentData] = useState({});
  const [invoiceNote, setInvoiceNote] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceNoteCount, setInvoiceNoteCount] = useState(0);
  const [secondData, setSecondData] = useState({
    "Receipt" : 1,
    "Receipt (copy)" : 1,
    // "Billing note (copy)" : 1,
    "Billing note" : 1,
  });
  
  const [mainDoc, setMainDoc] = useState([
    {
      "mainFile" : {
        "files" : {},
        "type" : null
      },
    }
  ])
  const Noti = new Notification()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [pdfFile, setPdfFile] = useState("");
  const [pdfName, setPdfName] = useState("");
  const navigate = useNavigate()

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setLoadingFirst(true)
      fnGetDocumentType()
    }
    },[]
  );

  const fnGetDocumentType = () => {
    getDocumentType().then(res => {
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let inv = res.data.resultData.find((x) => x.documentTypeName.toLowerCase() == "invoice")
        if(inv){
          let a = mainDoc
          a[0].mainFile.type = inv.documentTypeId
          setMainDoc(prevFileList => [...a]);
          mockSetting.current = JSON.parse(JSON.stringify(a[0]))
        }
      }else{
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
      }
      console.log(mainDoc)
      setLoadingFirst(false)
      setLoadingApi(false)
    }).catch(error => {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingFirst(false)
      setLoadingApi(false)
    })
  }

  const fnGetDocumentSetting = async (cus) => {
    try {
      let body = {
        customerCode : cus,
        docType : "1,3"
      }
      let res = await getDocumentSetting(body)
      let resData = res.data;
      if (resData.resultCode === "20000") {
        setCustomerSetting(res.data.resultData)
        setInvoiceNote(res.data.resultData?.customer?.invoiceNote)
        setInvoiceNoteCount(res.data.resultData?.customer?.invoiceNote?.length)
        let mainType = {...secondData}
        let m = res.data.resultData.documents.map((x) => {
          if(x.documentTypeName in mainType){
            mainType[x.documentTypeName] = x.qty;
          }

          return {
            "name": x.documentTypeName,
            "type" : x.documentTypeId,
            "isOther" : x.isOther,
            "qty" : x.qty,
            "files" : {},
          }
        })
        setSecondData(mainType)
        console.log("🚀 ~ fnGetDocumentSetting ~ mainType:", mainType)
        // setMainDoc(prevFileList => [{...prevFileList[0], otherFile: [...m]}]);
        setMockSettingOther(JSON.parse(JSON.stringify(m)))
        setLoadingApi(false)
        return m
      }else{
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        })
        setLoadingApi(false)
        return null
      }
    } catch (error) {
      if(error?.response?.data?.resultCode == "40401"){
        Noti.error({
          message: 'Error',
          description: "The configuration for this customer was not found. Please contact the administrator.",
        })
        navigate(`/generate-document`)
        return
      }
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingApi(false)
      return null
    }
  }

  const dummyRequest = async ({ file, onSuccess, onError }, type, typeId, index, indexOther) => {
    console.log("🚀 ~ dummyRequest ~ typeId:", typeId)

    const formData = new FormData();
    formData.append('files', file);
    formData.append('documentTypeId', typeId);
    try {
      let res = await postVerify({},formData)
      let a = mainDoc
      if(a.length > 0 && type == "main"){
        let dif = a.find((z, ind)=> {return (z.mainFile?.customerCode && z.mainFile?.customerCode != res.data.resultData.customerCode && ind != index)})
        if(dif){
          Noti.warning({
            message: 'Error uploading file.',
            description: "Please re-upload as customer data does not match." || ''
          })
          onError()
          return
        }
      }

      if(type == "main"){
        let isGetSetting = !!(a[index].mainFile.customerCode && (a[index].mainFile.customerCode != res.data.resultData.customerCode))
        console.log("🚀 ~ dummyRequest ~ isGetSetting:", isGetSetting)
        a[index].mainFile.files = file
        a[index].mainFile.customerCode = res.data.resultData.customerCode
        a[index].mainFile.customerName = res.data.resultData.customerName
        a[index].mainFile.orderNo = res.data.resultData.orderNo
        a[index].mainFile.content = res.data.resultData.contents
        a[index].mainFile.invoiceNumber = res.data.resultData.invoiceNumber
        let b;
        if(Object.keys(customerSetting).length == 0 || isGetSetting){
          b = await fnGetDocumentSetting(a[index].mainFile.customerCode)
          a[index].otherFile = b
          // change all a.otherFile = b
          a.forEach(item => {
            item.otherFile = b ? JSON.parse(JSON.stringify(b)) : [];
          });
          if(index == 0){
            setInvoiceDate(res?.data?.resultData?.invoiceDate)
          }
        }
        
        setMainDoc(prevFileList => [...a]);
        console.log([...a])
      }else if(type == "other"){
        a[index].otherFile[indexOther].files = file
        setMainDoc(prevFileList => [...a]);
      }
      onSuccess()
    } catch (error) {
      console.log("🚀 ~ dummyRequest ~ error:", error)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription || ''
      })
      onError();
    }
  };
  
  const beforeUpload = async (file) => {
    console.log("🚀 ~ beforeUpload ~ file:", file);
    
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Noti.warning({
        message: 'Error',
        description: "You can only upload PDF files!"
      })
      return false;
    }
  
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Noti.warning({
        message: 'Error',
        description: "PDF size is too large"
      })
      return false;
    }
  
    return true;
  };
  
  const handleChange = (e, index, type, otherName) => {
    if(e.file.status == "done"){
      let a = mainDoc
      if(type == "main"){
        a[index].mainFile.files = e.file
      }else{
        let f = a[index].otherFile.findIndex((x) => x.name == otherName)
        a[index].otherFile[f].files = e.file
      }
      setMainDoc(a);
      // setTimeout(() => {
      setMainDoc(prevFileList => [...a]);
      // }, 10);
      
      console.log("handleChange a", a[index])
      console.log("handleChange a", a)
    }
  };

  const handleSecondDataChange = (key, value) => {
    setSecondData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleInputChange = (index, field, value) => {
    const updatedMainDoc = [...mainDoc];
    const fileIndex = updatedMainDoc[index].otherFile.findIndex(file => file.name === field);
    if (fileIndex !== -1) {
      updatedMainDoc[index].otherFile[fileIndex].qty = value;
    }
    setMainDoc(updatedMainDoc);
  };

  const handleOk = () => {
    setPdfFile("");
    setPdfName("");
    setOpenPreview(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelPreview = () => {
    setPdfFile("");
    setPdfName("");
    setOpenPreview(false);
    if(isSendMail){
      navigate(`/generate-document`)
    }
  };

  const handleSaveContent = () => {
    if(contentData?.mainFile?.content.some(x => !x.trim())){
      Noti.warning({
        message: 'Field is required',
        description: "Your have left a field empty and a value must be entered."
      })
      return
    }
    const { index, ...s } = contentData;
    const updatedDoc = [...mainDoc];
    updatedDoc[index] = s;
    setMainDoc(updatedDoc);
    setContentData({});
    setOpen(false);
  }

  const addDoc = () =>{
    let a = mainDoc
    let b = JSON.parse(JSON.stringify(mockSetting.current))
    let c = mockSettingOther ? JSON.parse(JSON.stringify(mockSettingOther)) : []
    console.log("🚀 ~ addDoc ~ c:", c)
    a.push({...b, otherFile: [...c]})
    setMainDoc(prevFileList => [...a])
    // setMainDoc(prevFileList => [...prevFileList, {...mockSetting.current, otherFile: [...mockSettingOther]}]);
  }

  const addCreditNote = () =>{
    let a = creditNote
    let b = {
      creditNoteNumber : "",
      creditNoteDate : dayjs().format("DD/MM/YY"),
      creditNoteDueDate : dayjs().format("DD/MM/YY"),
      creditNoteAmount : "0.00"
    }
    a.push({...b})
    setCreditNote(prevFileList => [...a])
  }

  const removeDoc = (index) => {
    setMainDoc(prevFileList => {
      const n = [...prevFileList];
      n.splice(index, 1);
      if(!n.some((x)=>Object.keys(x.mainFile.files).length > 0)){
        setMockSettingOther()
        setCustomerSetting({})
        let b = JSON.parse(JSON.stringify(mockSetting.current))
        console.log("🚀 ~ removeDoc ~ mockSetting.current:", mockSetting.current)
        return [{...b}]
      }
      return n;
    });
  };

  const removeCreditNote = (index) => {
    setCreditNote(prevFileList => {
      const n = [...prevFileList];
      n.splice(index, 1);
      return n;
    });
  };

  const previewBtnClick = async (type) => {
    if(!isSendMail){
      await generatePreview(false, type)
    }else{
      if(type === "save"){
        downloadDoc()
      }else if(type === "print"){
        printDoc()
      }
    }
  }

  const downloadDoc = (file) => {
    const pdfUrl = file ? file : pdfFile
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = pdfName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    postEmail()
  };

  const printDoc = (file) => {
    if (pdfFile || file) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.src = file ? file : pdfFile;
      document.body.appendChild(iframe);
      iframe.contentWindow.onafterprint = () => {
        document.body.removeChild(iframe);
      };
      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      };
      postEmail()
    }
  };

  const postEmail = () => {
    if(!isSendMail){
      setIsSendMail(true)
      postSendEmail({customerCode: mainDoc[0].mainFile.customerCode, transactionId : transactionId})
      console.log("send mail")
    }
  }

  const goNextStep = async () => {
    try {
      console.log(mainDoc)
      const formData = new FormData();
      for (let i = 0; i < mainDoc.length; i++) {
        const m = mainDoc[i];
        if(Object.keys(m.mainFile.files).length == 0){
          Noti.warning({
            message: 'Field is required',
            description: "Please upload all required files"
          })
          return
        }
        formData.append('files', m.mainFile.files.originFileObj);
        formData.append('documentTypeId', m.mainFile.type);
        formData.append('docNo', i+1);
        if(m.otherFile.length > 0){
          for (let x = 0; x < m.otherFile.length; x++) {
            if(!m.otherFile[x].isOther || Object.keys(m.otherFile[x].files).length == 0) continue
            formData.append('files', m.otherFile[x].files.originFileObj);
            formData.append('documentTypeId', m.otherFile[x].type);
            formData.append('docNo', i+1);
          }
        }
      }
      console.log(formData);
      setLoadingApi(true)
      let res = await postUpload(mainDoc[0].mainFile.customerCode,formData)

      console.log("🚀 ~ goNextStep ~ res:", res)
      if(res && res.data && res.data.resultData){
        setTransactionId(res.data.resultData.transactionId)
      }
      setLoadingApi(false)
      setStep(2)
    } catch (error) {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingApi(false)
    }
  }

  const editContent = (x, i) => {
    let content = {
      ...x,
      index : i
    }
    console.log("🚀 ~ editContent ~ content:", content)
    setContentData(content)
    setOpen(true)
  }

  const generatePreview = async (isPreview, type) =>{
    try {
      setLoadingApi(true)
      console.log(mainDoc)
      let cn = []
      if (creditNote.length > 0) {
        let val = false;
        for (let y = 0; y < creditNote.length; y++) {
            let x = creditNote[y];
            if (!x.creditNoteNumber) {
                val = true;
                break;
            }
            cn.push({
                creditNoteNumber: x.creditNoteNumber,
                creditNoteDate: x.creditNoteDate,
                creditNoteDueDate: x.creditNoteDueDate,
                creditNoteAmount: x.creditNoteAmount
            });
        }
        if(val){
          Noti.warning({
            message: 'Field is required',
            description: "Please fill all required fields"
          })
          setLoadingApi(false)
          return
        }
      }
    
      let payload = {
        documentTypeId : mainDoc[0].mainFile.type,
        customer:{
          customerName: mainDoc[0].mainFile.customerName,
          customerCode: mainDoc[0].mainFile.customerCode,
          note : invoiceNote,
          transactionId : transactionId,
          billingDate: invoiceDate
        },
        creditNote: cn,
        document: [],
        isPreview: isPreview
      }
  
      for (let i = 0; i < mainDoc.length; i++) {
        const m = mainDoc[i];
        let setting = []
  
        if(m.otherFile.length > 0){
          for (let x = 0; x < m.otherFile.length; x++) {
            if(m.otherFile[x].name in secondData){
              m.otherFile[x].qty = secondData[m.otherFile[x].name]
            }
            setting.push({
              documentTypeId: m.otherFile[x].type,
              documentTypeName: m.otherFile[x].name,
              qty: +m.otherFile[x].qty
            })
          }
        }
        payload.document.push({
          docNo : i+1,
          contents : m.mainFile.content,
          setting : setting
        })
      }
      console.log(payload)
      let res = await postGenerate(payload)
      console.log("🚀 ~ generatePreview ~ res:", res)
      if(res?.data?.resultData){
        let name = res?.data?.resultData?.split('/').pop() || "document.pdf"
        let linkPdf = res.data.resultData
        const response = await fetch(linkPdf);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfName(name)
        setPdfFile(url);
        if(isPreview){
          setOpenPreview(true)
        }{
          if(type === "save"){
            downloadDoc(url)
          }else if(type === "print"){
            printDoc(url)
          }
        }
      }else{
        Noti.error({
          message: 'Error',
          description: res?.data?.resultDescription || ''
        })
      }
      setLoadingApi(false)
    } catch (error) {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      setLoadingApi(false)
    }
  }

  const showConfirm = () => {
    if(Object.keys(customerSetting).length > 0){
      Noti.confirm({
        message: 'Are you sure you want to cancel?',
        footer:[
          <div style={{display:"flex", justifyContent:"center", gap:"10px", marginTop:"20px"}}>
            <Button className="blue-btn" key="save"  onClick={() => {
              Modal.destroyAll();
              navigate(`/generate-document`)
            }}>
              Yes
            </Button>
            <Button className="cancel-btn" key="cancel" onClick={() => {
              Modal.destroyAll();
            }}>
              No
            </Button>
          </div>
        ]
      });
    }else{
      navigate(`/generate-document`)
    }
  };

  return <>
  <Layout activeKey="generate-document">
    <Helmet>
      <title>Document Manager</title>
    </Helmet>
    <Breadcrumb itemRender={itemRender} items={itemMenu} />
    {loadingFirst ? <SkeletonLoading /> : <>
      {step == 1 ? <>
        <div style={{padding:"20px"}}>
          <div className="header-action">
            <h2 style={{ textAlign: 'left',color:"#646965" }}>Upload Invoice</h2>
            <div style={{display:"flex", gap:"10px"}}>
              <span></span>
              <Button className="blue-btn" icon={<Arrow style={{color:"#fff"}}/>} style={{height:"40px", width:"100px"}} onClick={()=> goNextStep()} disabled={loadingApi}>Next</Button>
              <Button className="cancel-btn" icon={<CloseOutlined />} style={{height:"40px", width:"100px"}} onClick={()=>{showConfirm()}} disabled={loadingApi}>Cancel</Button>
            </div>
          </div>
          <Card className='card-list doc' bordered={false}>
          <Form
                form={form}
                onFinish={() => {
                }}
                onFinishFailed={()=> {
                }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
              >
            {mainDoc.length > 0 && <>
              {mainDoc.map((x, i)=>
                <div className="main-doc-container">
                  <div className="doc-set" style={{marginBottom:"20px"}}>
                    <h4>เอกสารชุดที่ {i+1}</h4>
                  </div>
                    <Form.Item label="เลขที่ใบกำกับภาษี" style={{marginBottom:"20px", minWidth:"150px"}} className="star">
                      <div style={{display:"inline"}}>
                        <Input className="input-upload" value={x?.mainFile?.files?.name} disabled/>
                        <Tooltip title="Upload" overlayClassName="tooltip-icon">
                          <Upload
                            customRequest={(e)=> dummyRequest(e, "main", x?.mainFile?.type, i)}
                            beforeUpload={beforeUpload}
                            onChange={(e)=>handleChange(e,i,"main")}
                          >
                            <Button icon={<UploadOutlined />}></Button>
                          </Upload>
                        </Tooltip>
                      </div>
                      <div style={{display:"inline-block"}}>
                        {(mainDoc.length-1 == i && mainDoc[0]?.mainFile?.files?.name) && mainDoc.length < 10 && 
                          <Tooltip title="New set document" overlayClassName="tooltip-icon">
                            <Button className="plus-btn" icon={<PlusOutlined />} style={{marginLeft:"10px"}} onClick={()=> addDoc()}></Button>
                          </Tooltip>}
                        {mainDoc.length > 1 && 
                        <Tooltip title="Delete set document" overlayClassName="tooltip-icon">
                          <Button className="minus-btn" icon={<MinusOutlined />} style={{marginLeft:"10px"}} onClick={()=> removeDoc(i)}></Button>
                        </Tooltip>}
                      </div>
                      {x?.mainFile?.customerCode && <div style={{marginLeft:"10px"}}>({x.mainFile.customerCode}) {x.mainFile.customerName}</div>}
                    </Form.Item>
                    {Array.isArray(x.otherFile) && x.otherFile.some(y => y.isOther) && <>
                      <Form.Item label="เอกสารอื่นๆ" style={{marginBottom:"10px"}} className="blank"></Form.Item>
                      {x.otherFile.map((y, z) => {
                        if (y.isOther) {
                          return (
                            <Form.Item key={z} label={y.name} style={{ marginBottom: "10px" }} >
                              <Input className="input-upload" value={y?.files?.name} disabled />
                              <Tooltip title="Upload" overlayClassName="tooltip-icon">
                                <Upload
                                  customRequest={(e) => dummyRequest(e, "other", y?.type, i, z)}
                                  beforeUpload={beforeUpload}
                                  onChange={(e) => handleChange(e, i, "other", y.name)}
                                >
                                  <Button icon={<UploadOutlined />}></Button>
                                </Upload>
                              </Tooltip>
                            </Form.Item>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>}
                </div>
              )}
            </>}
            </Form>
          </Card>
        </div>
      </> : step == 2 ? <>
        <div style={{padding:"20px"}}>
          <div className="header-action">
            <h2 style={{ textAlign: 'left',color:"#646965" }}>Requirement Document</h2>
            <div style={{display:"flex", gap:"10px"}}>
              <Button className="back-btn" icon={<Arrow style={{color:"#63A8C3", transform:"rotate(180deg)"}}/>} style={{height:"40px", width:"100px"}} onClick={()=> setStep(1)} disabled={loadingApi}>Back</Button>
              <Button className="blue-btn" style={{height:"40px", width:"100px"}} onClick={()=> generatePreview(true)} disabled={loadingApi}>Preview</Button>
              <Button className="cancel-btn" icon={<CloseOutlined />} style={{height:"40px", width:"100px"}} onClick={()=>{showConfirm()}} disabled={loadingApi}>Cancel</Button>
            </div>
          </div>
          <Form
            form={form2}
            onFinish={() => {
            }}
            onFinishFailed={()=> {
            }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
          >
            {mainDoc.length > 0 && <>
              <Card className='card-list doc' bordered={false} style={{marginBottom:"20px"}}>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="Customer ID" style={{ marginBottom: "20px"}}>
                      <Input style={{ width: '100%' }} value={mainDoc[0]?.mainFile?.customerCode} disabled/>
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="Customer Name" style={{ marginBottom: "20px"}}>
                      <Input style={{ width: '100%' }} value={mainDoc[0]?.mainFile?.customerName} disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="ใบเสร็จรับเงิน" style={{ marginBottom: "20px"}}>
                      <InputNumber min={0} max={20} defaultValue={1} style={{ width: '100%' }} value={secondData["Receipt"] || 0} onChange={(value) => handleSecondDataChange('Receipt', value)}/>
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="สำเนาใบเสร็จรับเงิน" style={{ marginBottom: "20px"}} >
                      <InputNumber min={0} max={20} defaultValue={1} style={{ width: '100%' }} value={secondData["Receipt (copy)"] || 0} onChange={(value) => handleSecondDataChange('Receipt (copy)', value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="ใบวางบิล" style={{ marginBottom: "20px"}}>
                      <InputNumber min={0} max={20} defaultValue={1} style={{ width: '100%' }} value={secondData["Billing note"] || 0} onChange={(value) => handleSecondDataChange('Billing note', value)}/>
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }}>
                    <Form.Item label="วันที่เอกสาร" style={{ marginBottom: "20px"}} className="star">
                      <DatePicker defaultValue={invoiceDate ? dayjs(invoiceDate, "DD/MM/YY") : dayjs()} format={"DD/MM/YY"} style={{ width: '100%' }} onChange={(value) => setInvoiceDate(dayjs(value).format("DD/MM/YY"))} allowClear={false} inputReadOnly/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '100%' }}>
                    <Form.Item
                      label="รายละเอียดเพิ่มเติม"
                      colon={false}
                      style={{ marginBottom: '20px' }}
                      labelCol={{ sm: { span: 6 }, xl: { span: 3 } }}
                      wrapperCol={{ sm: { span: 16 }, xl: { span: 21 } }}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        className="custom-textarea"
                        onChange={(e) => {setInvoiceNote(e.target.value);setInvoiceNoteCount(e.target.value.length)}}
                        maxLength={1000}
                        value={invoiceNote}
                      />
                    </Form.Item>
                    <div className="textarea-count">{invoiceNoteCount}/1000</div>
                  </Col>
                </Row>
                {creditNote.length === 0 ? <>
                  <div className="doc-set" style={{display:"flex",alignItems:"center"}}>
                    <h4>Credit Note</h4>
                    <Tooltip title="New credit note" overlayClassName="tooltip-icon">
                      <Button className="plus-btn" icon={<PlusOutlined />} style={{marginLeft:"10px"}} onClick={()=> addCreditNote()}></Button>
                    </Tooltip>
                  </div>
                </> : <>
                  {creditNote.map((x,y)=>{
                    return <>
                    <div className="doc-set" style={{display:"flex", alignItems:"center"}}>
                      <h4>Credit Note ชุดที่ {y+1}</h4>
                      {(creditNote.length-1 == y) && creditNote.length < 3 && 
                      <Tooltip title="New credit note" overlayClassName="tooltip-icon">
                        <Button className="plus-btn" icon={<PlusOutlined />} style={{marginLeft:"10px"}} onClick={()=> addCreditNote()}></Button>
                      </Tooltip>}
                      <Tooltip title="Delete credit note" overlayClassName="tooltip-icon">
                        <Button className="minus-btn" icon={<MinusOutlined />} style={{marginLeft:"10px"}} onClick={()=> removeCreditNote(y)}></Button>
                      </Tooltip>
                    </div>
                      <Row gutter={8} className="custom-col">
                        <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }} style={{marginBottom:"20px"}}>
                          <Form.Item label="เลขที่ Credit Note"  className="star">
                            <Input className={`${x.creditNoteNumber === '' ? 'ant-input-status-error' : ''}`} style={{ width: '100%' }} value={x.creditNoteNumber} onChange={(e) => {
                                const newCredit = [...creditNote];
                                newCredit[y].creditNoteNumber = e.target.value;
                                setCreditNote(newCredit);
                            }}/>
                          </Form.Item>
                        </Col>
                        <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }} style={{marginBottom:"20px"}}>
                          <Form.Item label="Credit Note Date" className="star">
                            <DatePicker defaultValue={x.creditNoteDate ? dayjs(x.creditNoteDate, "DD/MM/YY") : dayjs()} format={"DD/MM/YY"} style={{ width: '100%' }} allowClear={false} inputReadOnly
                              onChange={(value) => {
                                const newCredit = [...creditNote];
                                newCredit[y].creditNoteDate = dayjs(value).format("DD/MM/YY");
                                console.log("🚀 ~ {creditNote.map ~ newCredit[y].creditNoteDate:", newCredit[y].creditNoteDate)
                                setCreditNote(newCredit);
                            }}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8} className="custom-col">
                      <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }} style={{marginBottom:"20px"}}>
                        <Form.Item label="Credit Note Due Date" className="star">
                          <DatePicker defaultValue={x.creditNoteDueDate ? dayjs(x.creditNoteDueDate, "DD/MM/YY") : dayjs()} format={"DD/MM/YY"} style={{ width: '100%' }} allowClear={false} inputReadOnly
                            onChange={(value) => {
                              const newCredit = [...creditNote];
                              newCredit[y].creditNoteDueDate = dayjs(value).format("DD/MM/YY");
                              setCreditNote(newCredit);
                          }}/>
                        </Form.Item>
                      </Col>
                      <Col sm={{ flex: '100%', width:"100%" }} xl={{ flex: '50%' }} style={{marginBottom:"20px"}}>
                        <Form.Item label="Amount" className="star">
                          <InputNumber style={{width:"100%", maxWidth:"600px"}} min={0} defaultValue={0} value={parseFloat((x.creditNoteAmount || 0.00))}
                            stringMode
                            step="0.01"
                            onChange={(value) => {
                              const newCredit = [...creditNote];
                              newCredit[y].creditNoteAmount = Number(value).toFixed(2);;
                              setCreditNote(newCredit);
                          }}/>
                        </Form.Item>
                      </Col>
                    </Row></>
                  })}
                </>}
              </Card>
              <Card className='card-list doc' bordered={false}>
                {mainDoc.map((x, i)=>
                  <div className="main-doc-container">
                    <div className="doc-set" style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
                      <h4>เอกสารชุดที่ {i+1}</h4>
                      <Tooltip title="Edit" overlayClassName="tooltip-icon">
                        <span style={{cursor:"pointer",fontSize:"25px"}}><EditFilled onClick={()=> editContent(x, i)}/></span>
                      </Tooltip>
                    </div>
                      <Form.Item label="เลขที่ใบกำกับภาษี" style={{marginBottom:"20px", minWidth:"150px"}}>
                        <Input style={{width:"100%", maxWidth:"600px"}} value={x?.mainFile?.invoiceNumber} disabled/>
                      </Form.Item>
                      <Form.Item label="Order Number" style={{marginBottom:"20px", minWidth:"150px"}}>
                        <Input style={{width:"100%", maxWidth:"600px"}} value={x?.mainFile?.orderNo} disabled/>
                      </Form.Item>
                      <Form.Item label="ต้นฉบับใบกำกับภาษี" style={{marginBottom:"20px", minWidth:"150px"}} className="star">
                        <InputNumber style={{width:"100%", maxWidth:"600px"}} min={1} max={20} defaultValue={1} 
                        value={x.otherFile?.find(file => file.name === "Invoice")?.qty || 1} onChange={(value) => handleInputChange(i, "Invoice", value)}/>
                      </Form.Item>
                      <Form.Item label="สำเนาใบกำกับภาษี" style={{marginBottom:"20px", minWidth:"150px"}} >
                        <InputNumber style={{width:"100%", maxWidth:"600px"}} min={0} max={20} defaultValue={0} 
                        value={x.otherFile?.find(file => file.name === "Invoice (copy)")?.qty || 0} onChange={(value) => handleInputChange(i, "Invoice (copy)", value)}/>
                      </Form.Item>
                      {Array.isArray(x.otherFile) && x.otherFile.some(y => y.isOther && Object.keys(y.files).length > 0) && <>
                        <Form.Item label="เอกสารอื่นๆ" style={{marginBottom:"10px"}} className="blank"></Form.Item>
                        {x.otherFile.map((y, z)=>{if(y.isOther && Object.keys(y.files).length > 0){
                          return (<Form.Item label={y.name} style={{marginBottom:"10px"}}>
                            <InputNumber style={{width:"100%", maxWidth:"600px"}} min={0} max={20} defaultValue={1} value={y.qty || 0} 
                              onChange={(value) => {
                                const newMainDoc = [...mainDoc];
                                newMainDoc[i].otherFile[z].qty = value;
                                setMainDoc(newMainDoc);
                            }}/>
                          </Form.Item>)
                          }else {
                            return null;
                          }
                        })}
                      </>}
                      {mainDoc.length-1 != i && <hr  style={{margin:"50px 0 30px"}}/>}
                  </div>
                )}
              </Card>
            </>}
          </Form>
          
        </div>
      </> : <></>}
    </>}
    {loadingApi && <Spin className='spin-loading'></Spin>}
  </Layout>
  <Modal
    open={open}
    title={`Edit document set ${contentData?.index+1}`}
    onOk={handleSaveContent}
    onCancel={handleCancel}
    width={700}
    footer={[
      <Button icon={<SaveFilled />} className="blue-btn" onClick={handleSaveContent}>
        Save
      </Button>,
      <Button icon={<CloseOutlined />} className="cancel-btn" onClick={handleCancel}>
        Cancel
      </Button>
      
    ]}
  >
    <hr />
    <div style={{padding: "20px", minHeight:"300px", height:"calc(100vh - 400px)", overflowY:"auto"}}>
      <div className="modal-label-container">
        <label className="modal-label">Customer ID</label>
        <label>{contentData?.mainFile?.customerCode}</label>
      </div>
      <div className="modal-label-container">
        <label className="modal-label">Customer Name</label>
        <label>{contentData?.mainFile?.customerName}</label>
      </div>
      <div className="modal-label-container">
        <label className="modal-label">เลขที่ใบกำกับภาษี</label>
        <label>{contentData?.mainFile?.invoiceNumber}</label>
      </div>
      {contentData?.mainFile?.content.length > 0 && <>
        {contentData?.mainFile?.content.map((x, i)=> (
          <TextArea className={`bold-first-line ${!x ? 'require-red' : ''}`}  value={x} rows={4} placeholder="" style={{maxHeight:"200px", marginBottom:"20px", height:"200px",}} 
            onChange={(e) => {
              const newContent = [...contentData?.mainFile?.content];
              newContent[i] = e.target.value;
              setContentData({
                ...contentData,
                mainFile: {
                  ...contentData?.mainFile,
                  content: newContent
                }
              });
            }}
          />
        ))}
      </>}
    </div>
  </Modal>
  <Modal
    open={openPreview}
    title="Document Preview"
    onOk={handleOk}
    onCancel={handleCancelPreview}
    width={1000}
    style={{ marginTop:"-80px" }}
    footer={[
      <div style={{display:"flex", justifyContent:"center", gap:"20px"}}>
        <Tooltip title="Download" overlayClassName="tooltip-icon">
          <Button onClick={()=>previewBtnClick("save")} className="save-doc-btn">
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Print" overlayClassName="tooltip-icon">
          <Button onClick={()=>previewBtnClick("print")} className="print-doc-btn">
            <PrinterFilled />
          </Button>
        </Tooltip>
      </div>
    ]}
  >
    <hr />
    <div>
    <iframe
      id="pdfViewer"
      src={`${pdfFile}#toolbar=0`}
      width="100%"
      height="600px"
      title="PDF Viewer"
      style={{ border: 'none' }}
    />
    </div>
  </Modal>
  </>;
};

export default App;
