import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../component/layout/Layout";

const App = () => {
  // const initialized = useRef(false);

  // useEffect(() => {
  //     if (!initialized.current) {
  //       initialized.current = true;
  //     }
  //   },[]
  // );

  return <>
  <Layout>
    <Helmet>
      <title>Document Manager</title>
    </Helmet>
  </Layout>
  </>;
};

export default App;
