/* istanbul ignore file */
import { post, get, put } from './RequestApi'

export function getCustomer(params) {
  return get({ url: `/customer`, params});
}

export function postCustomer(data) {
  return post({ url: `/customer`, data })
}

export function postDocumentSetting(data) {
  return post({ url: `/document/setting`, data })
}

export function getDocumentSetting(params) {
  return get({ url: `/document/setting`, params});
}

export function putCustomer(data) {
  return put({ url: `/customer`, data })
}

export function putDocumentSetting(data) {
  return put({ url: `/document/setting`, data })
}